
import HelpVideoList from "@/components/HelpVideoList";
import PageTitle from "@/components/PageTitle";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  components: {
    HelpVideoList,
    PageTitle,
    Breadcrumbs
  }
};
