
import BaseIcon from "@/components/BaseIcon.vue";
import YouTube from "vue3-youtube";

export default {
  components: {
    BaseIcon,
    YouTube
  },

  props: {
    helpVideo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      confirmDelete: false,
      youTubeVars: {
        rel: 0,
        autoplay: 0,
        start: 0
      }
    };
  },
  computed: {
    videoLink() {
      return this.helpVideo.link + "?rel=0&autoplay=1&start=0";
    }
  },
  methods: {
    onReady() {
      this.$refs.youtube.playVideo();
    }
  }
};
