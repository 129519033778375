
import { mapState } from "vuex";
import draggable from "vuedraggable";
import HelpVideoListItem from "@/components/HelpVideoListItem.vue";
export default {
  components: {
    draggable,
    HelpVideoListItem
  },
  props: {},
  data() {
    return {
      loadError: "",
      saved: false
    };
  },
  computed: {
    ...mapState("helpVideos", ["list"])
  },
  async created() {
    await this.$store.dispatch("helpVideos/getAll");
  },
  methods: {
    async saveSequence() {
      this.saved = false;
      await this.$store.dispatch("helpVideos/saveSequence");
      this.saved = true;
    }
  }
};
