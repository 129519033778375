
import HelpVideoEditItem from "@/components/HelpVideoEditItem.vue";

export default {
  components: { HelpVideoEditItem },
  data() {
    return {
      breadcrumbs: [{ title: "Help Videos", link: "/help-videos" }]
    };
  }
};
