
import { mapState } from "vuex";
import BaseConfirmModal from "@/components/BaseConfirmModal.vue";
import YouTube from "vue3-youtube";

export default {
  components: { BaseConfirmModal, YouTube },

  props: {
    helpVideoId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      helpVideo: {},
      confirmDelete: false,
      youTubeVars: {
        rel: 0,
        autoplay: 1,
        start: 0
      }
    };
  },
  created() {
    console.log("helpVideoId");
    this.helpVideo = this.loadHelpVideo(this.helpVideoId);
  },
  computed: {
    ...mapState("helpVideos", ["list", "newHelpVideo"]),

    videoLink() {
      return this.helpVideo.link + "?rel=0&autoplay=1&start=0";
    }
  },
  methods: {
    loadHelpVideo(id) {
      let found = this.list.find(item => item.id === id);
      return Object.assign({}, found || this.newHelpVideo);
    },
    async saveHelpVideo(helpVideo) {
      console.log("saving Help Video", helpVideo);
      if (helpVideo.title && helpVideo.link) {
        if (helpVideo.id) {
          await this.$store.dispatch("helpVideos/updateHelpVideo", helpVideo);
        } else {
          helpVideo.sequence = this.list.length;
          await this.$store.dispatch("helpVideos/createHelpVideo", helpVideo);
        }
        this.$router.push("/help-videos");
      } else {
        console.log("Missing something to close");
      }
    },
    close() {
      this.$router.push("/help-videos");
    }
  },
  async deleteHelpVideo(helpVideo) {
    await this.$store.dispatch("helpVideos/deleteHelpVideo", helpVideo);
    confirmDelete = false;
    this.$router.push("/help-videos");
  }
};
